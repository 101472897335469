<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isAddNewContactSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-contact-sidebar-active', val)"
  >
  
    <template #default="{ hide }">
      <!-- Header -->
      <div v-if="isUploading">
      <div class="loading-logo">
        <img :src="require('@/assets/images/logo/logo.png')" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
      
      <div v-else>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Contact') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Tax ID -->
          <div v-if="uploadingError" class="error-uploading">{{t('Error CIF')}}</div>
          <validation-provider
            #default="validationContext"
            ref="taxidFocusv"
            name="RFC"
            rules="required|min:12"
          >
          <!-- Esta regexp es válida, sin embargo no realiza la validación ^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$ 
          para que se revise, por lo pronto se pone una validación que por lo menos sea de 12 posiciones.
          -->
            <div class="box-file">
              <b-form-group
              :label="t('Tax ID')"   
              ref="taxidFocusg"           
              label-for="taxid"
              :invalid-feedback="t('taxidr')"              
            >
              <b-form-input
                id="taxid"
                ref="taxidFocus"
                v-model="contactData.taxid"
                :autofocus=true
                :state="getValidationState(validationContext) && validateTaxID"
                :formatter="fmtUppercase"
                max="13"
                trim
                placeholder="XAXX010101AAA"
                @input="checkTaxId"
                class="taxid-input"
              />

              <!-- <b-form-invalid-feedback> ILL: 270122 se quitan de todos los campos los feedback, para posterior revisión de como manejarlos
                {{ validationContext.errors[0] }} ILL: 270122 se remplaza directo por :invalid-feedback
              </b-form-invalid-feedback> -->
            </b-form-group>
            <b-tooltip
              :title="t('Upload CIF tooltip')"
              class="cursor-pointer"
              :target="`files-upload-id`"
            />
            <form  enctype="multipart/form-data" novalidate @dragover="handleOnDragOver" @dragleave="onDragLeave" @drop="onDrop">
              <div class="container-input">
                <div class="dropbox" ref="uploading-box">
                  <input type="file" multiple :name="uploadFieldName"  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                    accept="application/pdf" class="input-file" id="files-upload-id" title="">
                    <b-icon 
                    id="cfdi-advisor"
                    icon="file-earmark-arrow-up"
                    scale="3"
                    class="rounded-circle"
                    color="#fff"
                  />
                </div>
                <span>{{t('Upload CIF')}}</span>
              </div>
            </form>
            </div>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Business Name"
            rules="required"
          >
            <b-form-group
              :label="t('Business Name')"
              label-for="username"
              :invalid-feedback="t('bnamereq')"
            >
              <b-form-input
                id="username"
                v-model="contactData.name"
                :state="getValidationState(validationContext)"
                :formatter="fmtUppercaseAll"
                 @input="removeErrorCIF"
                trim
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Alias -->
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="alias"
                rules="alpha|min:3"
              >
                <b-form-group
                  :label="t('Alias')"
                  label-for="alias"
                  :invalid-feedback="t('aliasval')"
                >
                  <b-form-input
                    id="alias"
                    v-model="contactData.alias"
                    :state="getValidationState(validationContext)"
                    :formatter="fmtUppercase10"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Phone"
                
              >
                <b-form-group
                  :label="t('Phone')"
                  label-for="phone"
                  :invalid-feedback="t('phoner')"
                >
                  <b-form-input
                    id="phone"
                    v-model="contactData.phone"
                    :state="getValidationState(validationContext)"
                    type="number"
                    :formatter="fmt10num"                
                    trim
                  />
                  <!--<b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>-->
                </b-form-group>
              </validation-provider>
            </b-col>    
          </b-row>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="t('Email')"
              label-for="email"                            
              v-b-tooltip.hover.top="t('temail')"
              :invalid-feedback="t('emailr')"              
              >
                  <b-form-input
                    id="email" 
                    v-model="contactData.email"
                    :state="getValidationState(validationContext)"                
                    trim                  
                  >              
                </b-form-input>
            </b-form-group>
            <!--<feather-icon icon="SearchIcon" />-->
          </validation-provider>

          <!-- Phone -->
          <!--<validation-provider
            #default="validationContext"
            name="Phone"
            
          >
            <b-form-group
              :label="t('Phone')"
              label-for="phone"
              :invalid-feedback="t('phoner')"              
            >
              <b-form-input
                id="phone"
                v-model="contactData.phone"
                :state="getValidationState(validationContext)"
                type="number"
                :formatter="fmt10num"
                trim
              />
            </b-form-group>
          </validation-provider>-->

          <!-- TaxPayer Type -->
          <validation-provider
            #default="validationContext"
            name="TaxPayer Type"
            rules="required|catalog"
          >
            <b-form-group
              :label="t('TaxPayer Type')"
              label-for="taxpayerType"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.taxertype"
                :state="getValidationState(validationContext)"
                :disabled="['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid)"
                text-field="v"
                label="v"
                :options="taxpayerList.filter(c => c.pt.find(o => o === contactData.type))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Invoice Use"
            rules="required|catalog"
          >
            <b-form-group
              :label="t('Invoice Use')"
              label-for="invoiceUse"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.invoiceuse"
                :state="getValidationState(validationContext)"
                :disabled="['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid)"
                text-field="v"
                label="v"
                :options="invoiceuseList.filter(c => (c.pt.find(o => o === contactData.type) && c.sr.find(u => u === contactData.taxertype.k)))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider my-2">
            <div v-if="contactData.taxid === 'XEXX010101000'" class="divider-text">
              {{ t('Addressonly') }}              
            </div>
            <div v-else-if="contactData.taxid === 'XAXX010101000'" class="divider-text">
              {{ t('Addressonly') }}              
            </div>
            <div v-else class="divider-text">
              {{ t('Address') }}              
            </div>                          
          </div>

          <!-- ZIP -->
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required|min:5"
          >
            <b-form-group
              :label="t('Zip')"
              label-for="zip"
              :invalid-feedback="t('zipr')"
            >
              <b-form-input
                id="zip"
                v-model="contactData.address.zip"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                :formatter="fmt5num"
                @change="checkZip(contactData.address.zip)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-row>
            <b-col cols="4">
              <b-form-group
                label-for="countryList"
                :label="t('Country')"
              >
                <v-select
                  id="countryList"
                  disabled
                  v-model="contactData.address.country"
                  text-field="v"
                  label="v"
                  :disabled="contactData.taxid !== 'XEXX010101000'"
                  :options="countryOption"
                />
              </b-form-group>
            </b-col>

            <!-- State -->
            <b-col cols="8">
              <b-form-group
                v-if="contactData.taxid !== 'XEXX010101000'"
                label-for="stateList"
                :label="t('State')"
              >
                <v-select
                  id="stateList"
                  disabled
                  v-model="contactData.address.state"
                  text-field="v"
                  label="v"
                  :options="stateOption"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Town -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            :label="t('Town')"
            label-for="townList"            
          >
            <v-select
              id="townList"
              disabled
              v-model="contactData.address.town"
              text-field="v"
              label="v"
              :options="townOption"
            />
          </b-form-group>

          <!-- Suburb -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            :label="t('Suburb')"
            label-for="suburbList"
            v-b-tooltip.hover.top="t('tcolonia')"
          >
            <v-select
              id="suburbList"
              v-model="contactData.address.suburb"
              text-field="v"
              label="v"
              :options="suburbOption"
            />
          </b-form-group>

          <!-- Street -->
          <b-form-group
            :label="contactData.taxid !== 'XEXX010101000' ? t('Street') : t('Direccion')"
            label-for="address-street"
          >
            <b-form-input
              id="street"
              v-model="contactData.address.street"
              
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isSaveEnabled"
            >
              {{ t('Add') }}   
              
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template> 

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, VBTooltip, BInputGroupAppend, BInputGroup, BMedia, BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, catalog } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import countryList from '@/@catalogs/sat/c_pais'
import store from '@/store'
import { BIcon, BTooltip } from 'bootstrap-vue'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroupAppend,
    BMedia,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BIcon,
    BTooltip,
    BRow,
    BCol
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewContactSidebarActive',
    event: 'update:is-add-new-contact-sidebar-active',
  },
  props: {
    isAddNewContactSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  
  computed: {
    isSaveEnabled: {
      get() {
        if(this.contactData.taxid !== '') {
          if(this.contactData.taxid !== '' && this.contactData.taxid !== 'XEXX010101000') {
            return this.taxIdValid &&
                    this.contactData.name !== '' &&
                    this.contactData.email !== '' &&
                    this.contactData.taxertype.k !== '' && 
                    this.contactData.invoiceuse.k !== '' &&
                    this.contactData.address.zip !== '' &&
                    this.contactData.address.suburb.k !== '' &&
                    this.contactData.address.street !== ''
          }

          if(this.contactData.taxid === 'XEXX010101000') {
            return this.taxIdValid &&
                    this.contactData.name !== '' &&
                    this.contactData.email !== '' &&
                    this.contactData.taxertype.k !== '' && 
                    this.contactData.invoiceuse.k !== '' &&
                    this.contactData.address.zip !== '' &&
                    this.contactData.address.street !== ''
          }
        }
       
        return false
      }
    },
    validateTaxID(){
      const regEx = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/
      const isValid = regEx.test(this.contactData.taxid);
      this.taxIdValid = isValid
      if(isValid){
        this.contactData.type = this.contactData.taxid.length === 12 ?'pm' : 'pf'
      }else{
        this.contactData.type = ''
      }
      return isValid;
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      catalog,
      stateOption: [],
      townOption: [],
      suburbOption: [],
      countryOption: countryList,
      invoiceuseList,
      taxpayerList,
      taxIdValid: false,
      // uploading file feature
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      isUploading: false,
      uploadingError: false
    }
  },
  setup(props, { emit }) {
    
    const blankContactData = {
      taxid: '', // RFC
      name: '', // Razon Social
      type: '', // Tipo de Persona
      alias: '', // Alias, campo opcional
      taxertype: { // Regimen Fiscal
        k: '',
        v: ''
      },
      invoiceuse: { // Uso de la Factura
        k: '',
        v: ''
      },
      email: '', // Correo Electronico
      phone: '', // Telefono
      address: {
        street: '',
        suburb: {
          k: '',
          v: ''
        },
        town: {
          k: '',
          v: ''
        },
        state: {
          k: '',
          v: ''
        },
        country: countryList.find(a => a.k === 'MEX'),
        meta: {
          sat: {
            country: '',
            state: '',
            town: '',
            suburb: '',
            st: {
              m: 0,
              a: '',
              h: '',
              gmt: 0
            },
            wt: {
              m: 0,
              a: '',
              h: '',
              gmt: 0
            }
          }
        },
        zip: '',
      },
      //status: 'enabled',
      //ac:  0, 
      //au:  Math.round((new Date()).getTime() / 1000)
    }

    /** lsandoval: 26/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    /** lsandoval: 25/02/2022 We import the toast utilities */
    const toast = useToast()

    const contactData = ref(JSON.parse(JSON.stringify(blankContactData)))
    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData))
    }

    const onSubmit = () => {
      // Hay que validar antes el envio
      store.dispatch('fg-contact/addContact', contactData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-contact-sidebar-active', false)
          
          const v0 = 'Datos Actualizados'
          //const v0 =  t('Data Updated')
          
          const v1 = 'Cliente Agregado'

          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: v0,
              icon: 'CheckIcon',
              variant: 'success',
              text: v1,
            },
          })

        })
        .catch((error) => {
          const v0 = 'Error al agregar al cliente'
          const v1 = 'El RFC ya existe, por favor verificar'
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: v0,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              text: v1,
            },
          })

        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    

    return {
      contactData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  methods: {
    fmtUppercase(value) {
        let v = value.toUpperCase()
        v = v.length > 13 ? v.substr(0,13) : v
        return v
    },
    fmtUppercase10(value) {
        let v = value.toUpperCase()
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },     
    // ILL: 110322 para que la longitud del campo nombre se transforme a mayusculas
    fmtUppercaseAll(value) {
        let v = value.toUpperCase()        
        return v
    },    
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo zip sea max = 5
    fmt5num(value) {        
        let v=value 
        v = v.length > 5 ? v.substr(0,5) : v
        return v
    },    
    checkZip (zip) {
      // Hay que validar antes el envio
      if(this.contactData.taxid === 'XEXX010101000') {
        this.stateOption = []
        this.townOption = []
        this.suburbOption = []

        this.contactData.address.state = { k: '',v: '' }
        this.contactData.address.town = { k: '',v: '' }
        this.contactData.address.suburb = { k: '',v: '' }

        return 
      }

      this.$store.dispatch('fg-contact/lookupZip', zip )
        .then((response) => {
          const bundle = response.data.bundle
          
          this.stateOption = [bundle.state]
          this.townOption = [bundle.town]
          this.suburbOption = [...bundle.suburbs]

          this.contactData.address.state = bundle.state
          this.contactData.address.town = bundle.town
        }).catch((error) => {
          this.stateOption = []
          this.townOption = []
          this.suburbOption = []

          this.contactData.address.state = { k: '',v: '' }
          this.contactData.address.town = { k: '',v: '' }
          this.contactData.address.suburb = { k: '',v: '' }
        })
    },
    checkTaxId () {
      this.uploadingError = false;
      if(['XAXX010101000','XEXX010101000'].find(a => a === this.contactData.taxid)) {
        const _taxertype = taxpayerList.find(a => a.k === '616')
        const _invoiceuse = invoiceuseList.find(a => a.k === 'S01')
        this.contactData.taxertype = _taxertype
        this.contactData.invoiceuse = _invoiceuse
      } else {
        this.contactData.taxertype = {k: '', v: ''}
        this.contactData.invoiceuse = {k: '', v: ''}
      }
    },
    
      async filesChange(fieldName, fileList) {
        this.uploadingError = false;
        this.isUploading = true;
        function _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        }
        const arrayBuffer = await fileList[0].arrayBuffer();
        const base64 = _arrayBufferToBase64(arrayBuffer)
        try {
          const response = await store.dispatch('fg-contact/getOCR', {
            sat: {
              cif: base64
            }
        })
        const data = response?.data?.data;
        const status = response?.data?.response;
        if(status==="success"){
          this.contactData.taxid = data?.qr_webpage?.taxid || ''
          if(data.qr_webpage.name && 
              data.qr_webpage.lname &&
              data.qr_webpage.slname){
                this.contactData.name = `${data.qr_webpage.name || ''} ${data.qr_webpage.lname || ''} ${data.qr_webpage.slname || ''}`
          }
          else if(data?.qr_webpage?.comercial_name){
            this.contactData.name = `${data.qr_webpage.comercial_name}`
          }
          
          for (const regimen of data?.qr_webpage?.regimen || []) {
            this.contactData.taxertype = taxpayerList.find(c => c.k === regimen.k )
            if(this.contactData.taxertype.k){
              break;
            }
          }
          if(data?.qr_webpage?.zip){
            this.checkZip(data?.qr_webpage?.zip)
            this.contactData.address.zip = data.qr_webpage.zip
          }
        }
        else{
          this.uploadingError = true;
        }
        } catch (error) {
          this.uploadingError = true;
        }finally{
          this.isUploading = false;
        }
      },
      handleOnDragOver(e){
        this.$refs["uploading-box"].classList.add("dropbox-over")
      },
      onDragLeave(){
        this.$refs["uploading-box"].classList.remove("dropbox-over")
      },
      onDrop(){
        this.$refs["uploading-box"].classList.remove("dropbox-over")
      },
      removeErrorCIF(){
        this.uploadingError = false;
      }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
