import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    lookupZip(ctx, zip) {
      return new Promise((resolve, reject) => {
        axios
          .post('/common/zip', { zip: zip })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listContact(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContact(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/get', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContact(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/delete', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContact(ctx, contact ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/add', { contact: contact })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContact(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/update', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOCR(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/utility/sat/cif/data', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
