<template>
  <b-sidebar
    id="edit-contact-sidebar"
    :visible="isEditContactSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-contact-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          <!-- Edit Contact {{ contactData.taxid }} ILL: 270122 no trae la variable taxid--> 
          {{ t('tedit') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Tax ID -->
          <validation-provider
            #default="validationContext"
            name="RFC"
            rules="required"
          >
            <b-form-group
              :label="t('Tax ID')"
              label-for="taxid"
              :invalid-feedback="t('taxidr')"

            >
              <b-form-input
                id="taxid"
                v-model="contactData.taxid"
                disabled
                autofocus
                :state="getValidationState(validationContext)"
                :formatter="fmtUppercase"
                trim
                placeholder="XAXX010101AAA"
                @change="contactData.taxid && contactData.taxid.length === 12 ? contactData.type = 'pm' : contactData.type = 'pf' "
              />

            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Business Name"
            rules="required"
          >
            <b-form-group
              :label="t('Business Name')"
              label-for="username"
              :invalid-feedback="t('bnamereq')"
            >
              <b-form-input
                id="username"
                v-model="contactData.name"
                :state="getValidationState(validationContext)"
                :formatter="fmtUppercaseAll"
                trim
              />

            </b-form-group>
          </validation-provider>

          <!-- Alias -->
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="alias"
                rules="alpha|min:3"
              >
                <b-form-group
                  :label="t('Alias')"
                  label-for="alias"
                  :invalid-feedback="t('aliasval')"
                >
                  <b-form-input
                    id="alias"
                    v-model="contactData.alias"
                    :state="getValidationState(validationContext)"
                    :formatter="fmtUppercase10"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Phone"
                
              >
                <b-form-group
                  :label="t('Phone')"
                  label-for="phone"
                  :invalid-feedback="t('phoner')"
                >
                  <b-form-input
                    id="phone"
                    v-model="contactData.phone"
                    :state="getValidationState(validationContext)"
                    type="number"
                    :formatter="fmt10num"                
                    trim
                  />
                  <!--<b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>-->
                </b-form-group>
              </validation-provider>
            </b-col>    
          </b-row>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="t('Email')"
              label-for="email"
              :invalid-feedback="t('emailr')"
            >
              <b-form-input
                id="email"
                v-model="contactData.email"
                :state="getValidationState(validationContext)"
                trim
              />

            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <!--<validation-provider
            #default="validationContext"
            name="Phone"
            
          >
            <b-form-group
              :label="t('Phone')"
              label-for="phone"
              :invalid-feedback="t('phoner')"
            >
              <b-form-input
                id="phone"
                v-model="contactData.phone"
                :state="getValidationState(validationContext)"
                type="number"
                :formatter="fmt10num"                
                trim
              />
            </b-form-group>
          </validation-provider>-->

          <!-- TaxPayer Type -->
          <validation-provider
            #default="validationContext"
            name="TaxPayer Type"
            rules="required"
          >
            <b-form-group
              :label="t('TaxPayer Type')"
              label-for="taxpayerType"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.taxertype"
                text-field="v"
                label="v"
                :disabled="['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid)"
                :options="taxpayerList.filter(c => c.pt.find(o => o === contactData.type))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Invoice Use"
            rules="required"
          >
            <b-form-group
              :label="t('Invoice Use')"
              label-for="invoiceUse"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.invoiceuse"
                text-field="v"
                label="v"
                :disabled="['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid)"
                :options="invoiceuseList.filter(c => (c.pt.find(o => o === contactData.type) && c.sr.find(u => u === contactData.taxertype.k)))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="session && session.businessFeatures && session.businessFeatures.length > 0 && contactData.features !== null" class="divider my-2">
            <div  class="divider-text">
              {{ t('Features') }}              
            </div>
          </div>  

          <b-form-group
          v-if="session && session.businessFeatures && session.businessFeatures.length > 0 && contactData.features !== null && contactData.features['fgFeatureCollections'] !== null"
            label-for="features-fg.feature.collections"
            :label="t('CLABE for Collections')"
          >
            <b-form-input
              id="features-fg.feature.collections"
              disabled
              v-model="contactData.features['fgFeatureCollections'].clabe"
            />
          </b-form-group>

          <div class="divider my-2">
            <div v-if="contactData.taxid === 'XEXX010101000'" class="divider-text">
              {{ t('Addressonly') }}              
            </div>
            <div v-else-if="contactData.taxid === 'XAXX010101000'" class="divider-text">
              {{ t('Addressonly') }}              
            </div>
            <div v-else class="divider-text">
              {{ t('Address') }}              
            </div>   
          </div>

          <!-- ZIP -->
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required"
          >
            <b-form-group
              :label="t('Zip')"
              label-for="zip"
              :invalid-feedback="t('zipr')"
            >
              <b-form-input
                id="zip"
                v-model="contactData.address.zip"
                :state="getValidationState(validationContext)"
                type="number"
                trim
                :formatter="fmt5num"
                @change="checkZip(contactData.address.zip)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-row>
            <b-col cols="4">
              <b-form-group
                label-for="countryList"
                :label="t('Country')"
              >
                <v-select
                  id="countryList"
                  disabled
                  v-model="contactData.address.country"
                  text-field="v"
                  label="v"
                  :disabled="contactData.taxid !== 'XEXX010101000'"
                  :options="countryOption"
                />
              </b-form-group>
            </b-col>

            <!-- State -->
            <b-col cols="8">
              <b-form-group
                v-if="contactData.taxid !== 'XEXX010101000'"
                label-for="stateList"
                :label="t('State')"
              >
                <v-select
                  id="stateList"
                  disabled
                  v-model="contactData.address.state"
                  text-field="v"
                  label="v"
                  :options="stateOption"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Town -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            label-for="townList"
            :label="t('Town')"
          >
            <v-select
              id="townList"
              disabled
              v-model="contactData.address.town"
              text-field="v"
              label="v"
              :options="townOption"
            />
          </b-form-group>
            

          <!-- Suburb -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            label-for="suburbList"
            :label="t('Suburb')"
          >
            <v-select
              id="suburbList"
              v-model="contactData.address.suburb"
              text-field="v"
              label="v"
              :options="suburbOption"
            />
          </b-form-group>

          <!-- Street -->
          <b-form-group
            label-for="address-street"
            :label="contactData.taxid !== 'XEXX010101000' ? t('Street') : t('Direccion')"
          >
            <b-form-input
              id="street"
              v-model="contactData.address.street"
              placeholder="Street and Number"
            />
          </b-form-group>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isSaveEnabled"
            >
              {{ t('Update') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import countryList from '@/@catalogs/sat/c_pais'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditContactSidebarActive',
    event: 'update:is-edit-contact-sidebar-active',
  },
  props: {
    isEditContactSidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    isSaveEnabled: {
      get() {
        if(this.contactData.taxid !== '') {
          if(this.contactData.taxid !== '' && this.contactData.taxid !== 'XEXX010101000') {
            return this.contactData.name !== '' &&
                   this.contactData.email !== '' &&
                   this.contactData.taxertype.k !== '' && 
                   this.contactData.invoiceuse.k !== '' &&
                   this.contactData.address.zip !== '' &&
                   this.contactData.address.suburb.k !== '' &&
                   this.contactData.address.street !== ''
          }

          if(this.contactData.taxid === 'XEXX010101000') {
            return this.contactData.name !== '' &&
                   this.contactData.email !== '' &&
                   this.contactData.taxertype.k !== '' && 
                   this.contactData.invoiceuse.k !== '' &&
                   this.contactData.address.zip !== '' &&
                   this.contactData.address.street !== ''
          }
        }
      }
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      stateOption: [],
      townOption: [],
      suburbOption: [],
      countryOption: countryList,
      invoiceuseList,
      taxpayerList,
      /** fer@arkeline.io
       *  Session Object for features
       */
      session: null
    }
  },
  setup(props, { emit }) {
    const blankContactData = (data) => {
      let o = {
          taxid: data.taxid ? data.taxid: '', // RFC
          name: data.name ? data.name: '', // Razon Social
          type: data.type ? data.type: '', // Tipo de Persona
          alias: data.alias ? data.alias: '', // Alias de la Persona/RazonSocial
          taxertype: { // Regimen Fiscal
            k: data.taxertype && data.taxertype.k ? data.taxertype.k: '',
            v: data.taxertype && data.taxertype.v ? data.taxertype.v: ''
          },
          invoiceuse: { // Uso de la Factura
            k: data.invoiceuse && data.invoiceuse.k ? data.invoiceuse.k: '',
            v: data.invoiceuse && data.invoiceuse.v ? data.invoiceuse.v: ''
          },
          email: data.email ? data.email: '', // Correo Electronico
          phone: data.phone ? data.phone: '', // Telefono
          address: {
            street: data.address && data.address.street ? data.address.street: '',
            suburb: {
              k: data.address && data.address.suburb ? data.address.suburb.k: '',
              v: data.address && data.address.suburb ? data.address.suburb.v: ''
            },
            town: {
              k: data.address && data.address.town ? data.address.town.k: '',
              v: data.address && data.address.town ? data.address.town.v: ''
            },
            state: {
              k: data.address && data.address.state ? data.address.state.k: '',
              v: data.address && data.address.state ? data.address.state.v: ''
            },
            country: {
              k: data.address && data.address.country ? data.address.country.k: '',
              v: data.address && data.address.country ? data.address.country.v: ''
            },
            meta: {
              sat: {
                country: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.country: '',
                state: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.state: '',
                town: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.town: '',
                suburb: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.suburb: '',
                st: {
                  m: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.m: 0,
                  a: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.a: '',
                  h: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.h: '',
                  gmt: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.gmt: -5
                },
                wt: {
                  m: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.m: 0,
                  a: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.a: '',
                  h: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.h: '',
                  gmt: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.gmt: -6
                }
              }
            },
            zip: data.address && data.address.zip ? data.address.zip: '',
          },
          /** fer@arkelne.io
           *  Features
           */
          features: data.features ? data.features : null
        }

        return o;
    }

    let contactUUID = ref(new String())
    let contactData = ref(JSON.parse(JSON.stringify(blankContactData({}))))
    let isEditContactSidebarActive = ref(false)

    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData({})))
    }

    const fetchContact = (uuid) => {
      // Hay que validar antes el envio
      //console.group('fg.views.contactListEdit fetchContact')
      //console.log('uuid',uuid)
      //console.groupEnd();
      store.dispatch('fg-contact/fetchContact', { uuid: uuid })
        .then((res) => {
          contactData.value = blankContactData(res.data) 
          contactUUID.value = uuid
          emit('update:is-edit-contact-sidebar-active', true)
          //isEditContactSidebarActive.value = true
        })
    }

    const onSubmit = () => {
      // Hay que validar antes el envio
      //console.group('fg.apps.contact.edit')
      //console.log('arguments',contactUUID,contactData)
      //console.groupEnd();
      store.dispatch('fg-contact/updateContact', { uuid:contactUUID.value, contact: contactData.value })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-contact-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    /** lsandoval: 27/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    return {
      contactData,
      fetchContact,
      onSubmit,

      //isEditContactSidebarActive,

      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  mounted() {
    this.session = JSON.parse(localStorage.getItem('session'))
  },
  methods: {
    fmtUppercase(value) {
        let v = value.toUpperCase()
        v = v.length > 13 ? v.substr(0,13) : v
        return v
    },
    fmtUppercase10(value) {
        let v = value.toUpperCase()
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },    
    // ILL: 110322 para que la longitud del campo nombre se transforme a mayusculas
    fmtUppercaseAll(value) {
        let v = value.toUpperCase()        
        return v
    }, 
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo zip sea max = 5
    fmt5num(value) {        
        let v=value
        v = v.length > 5 ? v.substr(0,5) : v
        return v
    },
    checkZip (zip) {
      // Hay que validar antes el envio
      this.$store.dispatch('fg-contact/lookupZip', zip )
        .then((response) => {
          const bundle = response.data.bundle
          
          this.stateOption = [bundle.state]
          this.townOption = [bundle.town]
          this.suburbOption = [...bundle.suburbs]

          this.contactData.address.state = bundle.state
          this.contactData.address.town = bundle.town
        }).catch((error) => {
          this.stateOption = []
          this.townOption = []
          this.suburbOption = []

          this.contactData.address.state = { k: '',v: '' }
          this.contactData.address.town = { k: '',v: '' }
          this.contactData.address.suburb = { k: '',v: '' }
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
