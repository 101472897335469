<template>

  <div>

    <contact-list-add-new
      ref="addContactComponent"
      :is-add-new-contact-sidebar-active.sync="isAddNewContactSidebarActive"
      @refetch-data="refetchData"
    />

    <contact-list-edit
      ref="editContactComponent"
      :is-edit-contact-sidebar-active.sync="isEditContactSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-left justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="t('Search...')"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <template v-if="session.business.address.main.zip === ''">
              <b-button
                variant="danger"
                :to="{ name: 'fg-account-settings'}"
              >
                <span class="text-nowrap">{{ $t('Complete Profile First') }}</span>
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="primary"
                @click="isAddNewContactSidebarActive = true"
              >
              <span class="text-nowrap">{{ $t('Add Contact') }}</span>
              </b-button>
            </template>

            
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refContactListTable"
        class="position-relative"
        :items="fetchContacts"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #head(business)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(taxid)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(le)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(te)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(actions)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(business)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="data.item.type.toUpperCase()"
                :variant="`light-${resolveContactTypeVariant(data.item.type)}`"
              />
            </template>
            <b-link :id="`contact-row-${data.item.uuid}-name`" class="font-weight-bold d-block text-nowrap">
              {{ data.item.name.length > 50 ? data.item.name.substr(0,50) + '. . .' : data.item.name }} <span v-if="data.item.alias !== ''" style="font-size: 9pt;"> ({{ data.item.alias}})</span>
            </b-link>
            <b-tooltip v-if="data.item.name.length > 50"
              class="cursor-pointer"
              :target="`contact-row-${data.item.uuid}-name`"
            >
              <span style="font-size: 7.5pt;">{{ data.item.name }}</span>
            </b-tooltip>
            <small class="text-black">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: TaxId -->
        <template #cell(taxid)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.taxid)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.taxid)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.taxid }}</span>
          </div>
        </template>

        <!-- Column: Fecha de Creación -->
        <template #cell(le)="data">
          <div class="text-nowrap">
            <template v-if="data.item.le === null">
              <b-badge
                pill
                variant="light-warning"
              >
                {{ $t('Non Invoiced') }}
              </b-badge>
            </template>
            <template v-else>
              <span class="align-text-top text-capitalize">{{ Intl.DateTimeFormat('es-MX', { day:'2-digit', month:'2-digit', year:'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(data.item.le)) }}</span>
            </template> 
          </div>
        </template>

        <template #cell(te)="data">
          <div class="text-nowrap">
            <template v-if="data.item.te === 0">
              <b-badge
                pill
                variant="light-warning"
              >
                {{ $t('Non Invoiced') }}
              </b-badge>
            </template>
            <template v-else>
              <b-link
                class="align-text-top text-capitalize link-underline"
                :to="{ path: `/fg/cfdi/list?taxid=${data.item.taxid}`}"
              >{{ data.item.te }}</b-link>
            </template> 
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <b-col cols="2" v-if="['XAXX010101000','XEXX010101000'].find(a => a !== data.item.taxid) && data.item.name !== 'PUBLICO EN GENERAL'">
              <feather-icon
                :id="`contact-row-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="fetchContact(data.item.uuid)"
              />
              <b-tooltip
                :title="t('Edit')"
                class="cursor-pointer"
                :target="`contact-row-${data.item.uuid}-edit-icon`"
              />
            </b-col>
            <b-col cols="2">
              <feather-icon
                :id="`contact-row-${data.item.uuid}-invoice-icon`"
                icon="FileTextIcon"
                class="cursor-pointer"
                size="16"
                @click="addCfdiFromContact(data.item.uuid)"
              />
              <b-tooltip
                :title="t('invoiceit')"
                class="cursor-pointer"
                :target="`contact-row-${data.item.uuid}-invoice-icon`"
              />
            </b-col>
            <b-col cols="2" v-if="['XAXX010101000','XEXX010101000'].find(a => a !== data.item.taxid) && data.item.name !== 'PUBLICO EN GENERAL' && data.item.te === 0">
              <feather-icon
                :id="`contact-row-${data.item.uuid}-delete-icon`"
                icon="Trash2Icon"
                class="cursor-pointer"
                size="16"
                @click="deleteContact(data.item.uuid)"
              />
              <b-tooltip
                :title="t('delete')"
                class="cursor-pointer"
                :target="`contact-row-${data.item.uuid}-delete-icon`"
              />
            </b-col>
            <b-col :cols="['XAXX010101000','XEXX010101000'].find(a => a !== data.item.taxid) && data.item.name !== 'PUBLICO EN GENERAL' && data.item.te === 0 ? 6 : 8">
            </b-col>
          </b-row>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Showing') }} {{ dataMeta.from }} {{ t('to') }} {{ dataMeta.to }} {{ t('of') }} {{ dataMeta.of }} {{ t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import useContactList from './useContactList'
import contactStoreModule from './contactStoreModule'
import ContactListAddNew from './ContactListAddNew.vue'
import ContactListEdit from './ContactListEdit.vue'

export default {
  components: {
    ContactListAddNew,
    ContactListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  mounted() {
    this.$root.$on('session-refresh',(message) => {
      this.session = JSON.parse(localStorage.getItem('session'))
      if (this.$store.hasModule(this.USER_APP_STORE_MODULE_NAME)) 
        this.refetchData()
    })
  },
  methods: {
    addCfdiFromContact (uuid) {
      this.$router.replace({ name: 'fg-cfdi-add-from-contact', params: { cuuid: uuid }})
        .then(() => {
          
        })
    }
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'fg-contact'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, contactStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let isAddNewContactSidebarActive = ref(false)
    let isEditContactSidebarActive = ref(false)
    /** lsandoval: 13/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    const vm = getCurrentInstance().proxy

    /** lsandoval: 16/02/2022 We add the session to handle emisor data */
    let session = JSON.parse(localStorage.getItem('session'))

    const deleteContact = (uuid) => {
      // Hay que validar antes el envio
      //console.group('fg.views.contactList deleteContact')
      //console.log('uuid',uuid)
      //console.groupEnd();
      store.dispatch('fg-contact/deleteContact', { uuid: uuid })
        .then((res) => {
          refetchData()
        })
    }

    const {
      fetchContacts,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveContactTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useContactList()

    const fetchContact = (uuid) => {
      let v = vm.$refs['editContactComponent']
      v.fetchContact(uuid)
    }

    return {
      // Sidebar
      isAddNewContactSidebarActive,
      isEditContactSidebarActive,

      fetchContacts,
      fetchContact,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactListTable,
      refetchData,
      deleteContact,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveContactTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      // session
      session,
      // i18n
      t,

      USER_APP_STORE_MODULE_NAME
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
